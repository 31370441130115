import {ESLMedia, ESLMediaHookEvent} from '@exadel/esl/modules/esl-media/core';
import {SyntheticEventTarget, ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom';
import {memoize, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {isReducedMotion} from '@exadel/esl/modules/esl-utils/environment';

export class HPEMediaRestriction extends SyntheticEventTarget {
  protected static readonly KEY = 'cinematic-control';

  @memoize()
  static get instance(): HPEMediaRestriction {
    return new HPEMediaRestriction();
  }

  static init(): void {
    ESLEventUtils.subscribe(this.instance, this.instance.onPlayListener);
  }

  public get allowed(): boolean {
    const value = localStorage.getItem(HPEMediaRestriction.KEY) || (isReducedMotion ? 'off ' : 'on');
    localStorage.setItem(HPEMediaRestriction.KEY, value);
    return value === 'on';
  }

  public set allowed(value: boolean) {
    localStorage.setItem(HPEMediaRestriction.KEY, value ? 'on' : 'off');
    this.dispatchEvent(new CustomEvent('change'));
  }

  @listen({event: 'esl:media:before:play', target: window})
  protected onPlayListener(e: ESLMediaHookEvent): void {
    if (this.allowed) return;
    const $media = e.target as ESLMedia;
    if (($media.mediaType === 'video' || $media.muted) && e.initiator !== 'user') {
      e.preventDefault();
    }
  }
}
