import {DEFAULT_EVENT_PREFIX, isReferrerMatch, fireAnalyticsEvent} from 'core/helpers/metrics';
import Cookies from 'js-cookie';

const convertPathnameToPagentPage = (pathname: string): string => {
  return pathname.replace('.html', '').split('/').slice(3).join(':') || '';
};

export abstract class MetricsMyAccount {
  static readonly hvaCreateAccountStart = 'hva.createaccount.start';
  static readonly hvaCreateAccountComplete = 'hva.createaccount.complete';
  static readonly hvaSignInSuccess = 'hva.sign-in.success';

  static readonly eventLink = `${DEFAULT_EVENT_PREFIX}.LINK`;

  static trackCreateAccountStart(getParentPageFn: () => string = MetricsMyAccount.getParentPageForCreateAccount): void {
    fireAnalyticsEvent({
      'type': MetricsMyAccount.hvaCreateAccountStart,
      'parentPage': getParentPageFn()
    });
  }

  static trackCreateAccountComplete(getParentPageFn: () => string, email: string): void {
    fireAnalyticsEvent({
      'type': MetricsMyAccount.hvaCreateAccountComplete,
      'parentPage': getParentPageFn(),
      'emid': email
    });
  }

  static trackSignInSuccess(email: string): void {
    if (MetricsMyAccount.islockedSignInSuccessMutex()) return;

    fireAnalyticsEvent({
      'type': MetricsMyAccount.hvaSignInSuccess,
      'parentPage': MetricsMyAccount.getParentPageForSignInSuccess(),
      'emid': email
    });
    MetricsMyAccount.lockSignInSuccessMutex();
  }

  static fireMyAccountEditPhotoEvent(): void {
    fireAnalyticsEvent({
      link_name: 'my-account:edit-photo'
    }, MetricsMyAccount.eventLink);
  }

  static fireMyAccountEditPhotoCompleteEvent(): void {
    fireAnalyticsEvent({
      link_name: 'my-account:edit-photo:complete'
    }, MetricsMyAccount.eventLink);
  }

  static fireMyAccountUploadPhotoEvent(): void {
    fireAnalyticsEvent({
      link_name: 'my-account:upload-photo'
    }, MetricsMyAccount.eventLink);
  }

  static fireMyAccountRemovePhotoEvent(): void {
    fireAnalyticsEvent({
      link_name: 'my-account:remove-photo'
    }, MetricsMyAccount.eventLink);
  }

  static islockedSignInSuccessMutex(): boolean {
    return Cookies.get(MetricsMyAccount.hvaSignInSuccess) === '1';
  }

  static lockSignInSuccessMutex(): void {
    Cookies.set(MetricsMyAccount.hvaSignInSuccess, '1');
  }

  static unlockSignInSuccessMutex(): void {
    Cookies.set(MetricsMyAccount.hvaSignInSuccess, '0');
  }

  static getParentPageFromCurrent(): string {
    return convertPathnameToPagentPage(window.location.pathname);
  }

  static getParentPageFromReferer(): string {
    return convertPathnameToPagentPage(new URL(document.referrer).pathname);
  }

  static getParentPageForCreateAccount(): string {
    if (document.referrer) {
      const sp = new URLSearchParams(window.location.search);
      if (sp.has('redirectURL')) {
        return sp.get('redirectURL');
      }

      if (!sp.has('resource') && isReferrerMatch()) {
        return MetricsMyAccount.getParentPageFromReferer();
      }
    }

    return 'no-parent-page';
  }

  static getParentPageForSignInSuccess(): string {
    return MetricsMyAccount.getParentPageFromCurrent(); // for hpe-okta
  }
}
