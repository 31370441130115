import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {HPEMediaRestriction} from '../../media/ui/media.resctriction';

export class HpeMediaRestrictionMixin extends ESLMixinElement {
  static override is = 'hpe-media-restriction';
  public override $host: HTMLInputElement;
  protected override connectedCallback(): void {
    super.connectedCallback();
    this.onUpdate();
  }

  @listen({event: 'change', target: HPEMediaRestriction.instance})
  protected onUpdate() {
    this.$host.checked = HPEMediaRestriction.instance.allowed;
  }

  @listen('input')
  protected onStatusChanged(): void {
    const action = this.$host.checked ? 'release' : 'suspend';
    HPEMediaRestriction.instance.allowed = this.$host.checked;
    this.$$fire('esl:media:managedaction', {detail: {action}});
  }
}

export default {
  initialize: () => HpeMediaRestrictionMixin.register()
};
